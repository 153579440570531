﻿import $ from 'jquery';
import 'webpack-jquery-ui'

window.jQuery = $;
window.$ = $;

import 'knockout/build/output/knockout-latest';
import 'knockout-mapping/dist/knockout.mapping';
import 'jquery.cookie'
import 'jquery.scrollto';
import 'jstz';
import 'qTip2';
import 'jquery-validation';
import Inputmask from "inputmask";
import 'inputmask/dist/inputmask/jquery.inputmask';

window.Inputmask = Inputmask;

import Globalize from 'globalize';
import 'globalize/lib/cultures/globalize.culture.en-029.js';
import 'globalize/lib/cultures/globalize.culture.en-AU.js';
import 'globalize/lib/cultures/globalize.culture.en-BZ.js';
import 'globalize/lib/cultures/globalize.culture.en-CA.js';
import 'globalize/lib/cultures/globalize.culture.en-GB.js';
import 'globalize/lib/cultures/globalize.culture.en-IE.js';
import 'globalize/lib/cultures/globalize.culture.en-IN.js';
import 'globalize/lib/cultures/globalize.culture.en-JM.js';
import 'globalize/lib/cultures/globalize.culture.en-MY.js';
import 'globalize/lib/cultures/globalize.culture.en-NZ.js';
import 'globalize/lib/cultures/globalize.culture.en-PH.js';
import 'globalize/lib/cultures/globalize.culture.en-SG.js';
import 'globalize/lib/cultures/globalize.culture.en-TT.js';
import 'globalize/lib/cultures/globalize.culture.en-US.js';
import 'globalize/lib/cultures/globalize.culture.en-ZA.js';
import 'globalize/lib/cultures/globalize.culture.en-ZW.js';

window.Globalize = Globalize;